export default {
    editItem(data) {
    },

    store() {
        this.form.journal = this.journal;
        this.form.note_type = 'cr';
        this.form.journal.ledger_head_id = this.form.items.data[0].ledger_accounts[0].ledger_head_id
        this.form.journal = this.journal;
        this.form.narration = this.journal.narration;
        if(!this.form.narration) {
            this.$events.fire('notification', {message: 'Narration is required',status:'error'});
        }else {
            this.form.ledger = this.form.ledger.filter(
                item => item.cr > 0
            ).map(item => {
                return {ledger_head_id: item.ledger_head_id, drcr: item.cr, amount: item.amount}
            });

            // console.log(this.form.ledger);
            this.isLoading = true
            this.$rest.post('/api/drcr-note', this.form).then(res => {
                    this.chg = false;
                    this.rev = false;
                    this.journal.narration = null;
                    this.form.items = [];
                    this.tag = false;
                    this.form.reset();
                    this.$events.fire('notification', {message: res.data.message,status:'success'});
                }
            ).catch(e => {
                this.$events.fire('notification', {message: e.message,status:'error'});
            }).finally(() => {
                this.isLoading = false
            });
        }

    },

    storeLedger() {
        this.ledgerForm.store().then(({data}) => {
            this.getLedgerHeads(data.data.id);
        })
    },

    // Journal Types
    getJournalTypes() {
        this.$rest.get('/api/journal-type').then(({data}) => {
            this.pr_type = data.data.filter(item => item.pr_type === 'receipt').map(item => {
                return {value: item.id, text: item.name}
            });
        })
    },

    // account heads
    getAccountHeads() {
        this.$rest.get('/api/account-head').then(({data}) => {
            this.accountHeads = data.data.map(item => ({value: item.id, text: item.name}));
        })
    },

    getLedgerHeads(id) {
        this.$rest.get('/api/ledger-head').then(({data}) => {
            this.ledgerHeads = data.data.map(item => ({value: item.id, text: item.name}));
            if (![undefined, null].includes(id)) {
                let len = this.form.ledger.length;
                if (len > 0) this.form.ledger[len - 1].ledger_head_id = id;
                else this.form.ledger[0].ledger_head_id = id;
            }
        })
    },

    addRow: function () {
        if ([undefined, null].includes(this.form.ledger)) {
            this.form.ledger = [{ledger_head_id: null, total_amount: 0, tax: 0, net_amount: 0}];
        } else {
            this.form.ledger.push({ledger_head_id: null, total_amount: 0, tax: 0, net_amount: 0});
        }
    },
    removeElement: function (row) {
        var index = this.form.ledger.indexOf(row);
        this.form.ledger.splice(index, 1);
    },

    ledgerChange(data) {
        let head = this.ledgerHeads.find(item => item.value == data.ledger_head_id);
        data.ledger_head_name = head.text;
    },

    getSerialNumber() {
        this.$rest.get('/api/payment-receipt/serial?type=receipt').then(({data}) => {
            this.form.serial_no = data.data.serial;
        });
    },

    lazySum(row) {
        return new Promise((resolve, reject) => {
            row.net_amount = parseFloat(row.amount) + parseFloat(row.tax);
            resolve(row);
        })
    },

    sumNetAmount(item) {
        this.journal.final_amount = this.form.ledger.filter(item => item.cr > 0).map(it =>
            parseFloat(it.amount)
        ).reduce((x, y) => x + y, 0);
    },

    setEntryDate() {
        if (this.journal.entry_date == null) {
            let date = this.$converter.todayNP();
            this.journal.entry_date = `${date.year}-${date.month.pad(2)}-${date.day.pad(2)}`;
        }
    },
    submit() {
        if(this.form.serial_no){
            this.$rest.get('/api/drcr-note/ledgers?serialNo=' + this.form.serial_no + '&fiscal_year_id=1').then(({data}) => {
                this.form.items = data;
                if (data.data.length > 0) {
                    this.form.ledger = data.data[0].ledger_accounts;
                    this.journal.final_amount = data.data[0].ledger_accounts.map(
                        it => parseFloat(it.cr)
                    ).reduce((x, y) => x + y, 0);
                }
                if (data.data.length > 0) this.tag = true;
                else this.tag = false;
                this.chg = false;
                this.rev = false;
            })
        }
    },

    reverse() {
            this.form.ledger = this.form.items.data[0].ledger_accounts;
            this.chg = false;
            this.tag = true;
            this.form.ch_rev = 'rev';
            this.rev = true
        if(this.form.data.length > 0) {

            let i = 0;
            for (i = 0; i < this.form.items.data[0].ledger_accounts.length; i++) {
                if (this.form.items.data[0].ledger_accounts[i].dr > 0) {
                    this.form.items.data[0].ledger_accounts[i].drcr = 'cr'
                    this.form.items.data[0].ledger_accounts[i].amount = this.form.items.data[0].ledger_accounts[i].dr
                }

                if (this.form.items.data[0].ledger_accounts[i].cr > 0) {
                    this.form.items.data[0].ledger_accounts[i].drcr = 'dr'
                    this.form.items.data[0].ledger_accounts[i].amount = this.form.items.data[0].ledger_accounts[i].cr
                }
            }
        }

    },

    change() {
            this.form.ledger = this.form.items.data[0].ledger_accounts;
            this.chg = true;
            this.tag = true;
            this.rev = false;
            this.form.ch_rev = 'ch';

            if(this.form.data.length > 0) {
            let i = 0;
            for (i = 0; i < this.form.items.data[0].ledger_accounts.length; i++) {
                if (this.form.items.data[0].ledger_accounts[i].dr > 0) {
                    this.form.items.data[0].ledger_accounts[i].drcr = 'dr'
                    this.form.items.data[0].ledger_accounts[i].amount = this.form.items.data[0].ledger_accounts[i].dr
                }

                if (this.form.items.data[0].ledger_accounts[i].cr > 0) {
                    this.form.items.data[0].ledger_accounts[i].drcr = 'cr'
                    this.form.items.data[0].ledger_accounts[i].amount = this.form.items.data[0].ledger_accounts[i].cr
                }
            }
        }

    }

}
