import Form from '@/library/Form';

export default {
    form: new Form({
            serial_no: '',
            note_type: 'dr',
            ch_rev: 'ch',
            // journal_type_id: '',
            // particulars: [{ledger_head_id: null, drcr: 'dr', amount: 0}],
            ledger: [{ledger_head_id: null, drcr: 'dr', amount: 0}],
            journal: '',
            narration:'',
        },
        '/api/drcr-note/ledgers'
    ),
    headers: [
        {text: 'Date', value: 'nepali_date', align: "left", sortable: false},
        {text: 'Serial No', value: 'serial_no', align: "left", sortable: false},
        {text: 'Particular', value: 'particular', align: "left", sortable: false},
        // { text: 'LF Id', value: 'lf_id', align: "center" },
        {text: 'Dr.', value: 'dr', align: "right", sortable: false},
        {text: 'Cr.', value: 'cr', align: "right", sortable: false},
        {text: 'User', value: 'user', align: "center", sortable: false},
    ],
    download: false,
    tag: false,
    chg: false,
    rev: false,
    journal: {
        narration: null,
        ledger_head_id: null,
        entry_date: null,
        final_amount: null,
    },
    ledgerHeads: [], /** Ledger Heads **/

    journalTypes: [], /** Journal Types **/

    accountHeads: [], /** Account Heads **/
    ledger_head_id: null,
    amount: null,

    drcrList: [
        {value: 'dr', text: 'DR'},
        {value: 'cr', text: 'CR'}
    ],
    isLoading: false,



}
