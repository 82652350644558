export default function mounted() {
	
    /** Get Account Heads **/
    this.getJournalTypes();

	/** Get Account Heads **/
    this.getLedgerHeads();

	/** Get Account Heads **/
    this.getAccountHeads();
}
